.flashContainer {
    position: fixed;
    right: 20px;
    top: 70px;
}

.alert-visible {
    opacity: 0.8;
}

.alert-fading {
    opacity: 0.0;
    transition: opacity 2s;
}
