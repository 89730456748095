canvas {
    margin: auto;
    opacity: 0.95;
    position: inherit !important;
    z-index: 500;
}

.noSelect {
    user-select: none !important;
}

.docArea {
    min-height: 100vh;
    background: gray;
    padding: 0px;
    margin: 10px;
}

.watermark {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    font-size: 70px;
    color: #FFFFFF;
    rotate: 45deg;
    z-index: 1;
    text-align: center;
}

.url {
    font-family: monospace;
    font-size: 70px;
}