#navbar-right-btn {
    background: #444a50;
    border: 1px solid white;
    padding: 2px 5px 2px 10px;
    color: white;
    border-radius: 3px;
}

#navbar-right-btn:focus {
    outline: none;
    border: 2px solid white;    
}

#navbar-right-btn:active {
    background:black;
    border: none !important;
    padding: 2px 5px 2px 10px;
}

#navbar-right-img {
    max-width: 32px;
    margin-top: 1px;
    /* border: 1px solid white; */
    margin-right: 5px;
}

#navbar-user-card {
    position: fixed;
    top: 55px;
    right: 5px;
    min-width: 200px;
    max-width: 600px;
    background: white;
    color: black;
    border: 2px solid black;
    box-shadow: 0px 0px 20px black;
}

#navbar-user-card img {
    display: block;
    width: 100px;
    margin: auto;
}

#navbar-user-card .options {
    color: gray;
    float: right
}