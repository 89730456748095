.navbar {
    padding: 0px 10px !important;
}

.header-title a {
    font-family: 'Share Tech', sans-serif;
    font-size: 30px;
    letter-spacing: 1px;
    color: lightblue !important;
    text-decoration: underline;
    text-decoration-color: lightseagreen;
    text-decoration-thickness: 0.08em;
    transition: text-shadow 0.3s;
}

.header-title a:hover {
    text-shadow: 0px 0px 10px white;
    transition: text-shadow 0.3s;
}

.container-main {
    margin-top: 10px;
    font-size: 14px;
}